<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form ref="openingHoursForm">
      <v-container>
        <v-row class="mt-3">
          <v-col cols="12" md="8">
            <h5>Please fill in the opening hours of your venue</h5>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What is the weekly schedule of the venue?</v-card>
          </v-col>
        </v-row>
        <v-row v-for="(day, index) in form.opening_hours" :key="index">
          <v-col cols="12" md="2" class="py-0">
            <div class="pt-5 mt-1">{{ form.opening_hours[index].day }}</div>
          </v-col>
          <v-col cols="12" md="2" class="py-0">
            <v-switch
              inset
              :key="index"
              v-model="form.opening_hours[index].is_open"
              :label="form.opening_hours[index].is_open === true ? 'Open' : 'Close'"
            ></v-switch>
          </v-col>
          <v-col cols="12" md="4" class="py-0 d-flex">
            <v-select
              v-model="form.opening_hours[index].start_time"
              :key="'start_' + index"
              label="Start Time"
              :items="timings"
            ></v-select>
            <span class="mt-5 ml-2 mr-2">-</span>
            <v-select
              v-model="form.opening_hours[index].end_time"
              :key="'end_' + index"
              label="End Time"
              :items="timings"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col cols="12">
            <h5>Please fill in the opening seasons of venue</h5>
          </v-col>
        </v-row>

        <v-row class="my-0" v-for="(openingSeason, index) in form.seasonal_timings" :key="'seasons_' + index">
          <v-col cols="12" md="4">
            <v-menu
              v-model="form.seasonal_timings[index].start_date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.seasonal_timings[index].start_date"
                  label="From Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">
                    <v-btn icon @click="form.seasonal_timings[index].start_date_menu = true">
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                :min="currentDate"
                v-model="form.seasonal_timings[index].start_date"
                @input="form.seasonal_timings[index].start_date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="d-flex">
            <v-menu
              v-model="form.seasonal_timings[index].end_date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.seasonal_timings[index].end_date"
                  label="To Date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                >
                  <template slot="append">
                    <v-btn icon @click="form.seasonal_timings[index].end_date_menu = true">
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template></v-text-field
                >
              </template>
              <v-date-picker
                :min="form.seasonal_timings[index].start_date"
                v-model="form.seasonal_timings[index].end_date"
                @input="form.seasonal_timings[index].end_date_menu = false"
              ></v-date-picker>
            </v-menu>
            <span
              v-if="form.seasonal_timings[index].removable"
              class="mt-5 ml-2 mr-2 pointer"
              @click="removeOpeningSeason(index)"
              ><i class="mdi mdi-close"></i
            ></span>
          </v-col>
          <v-col cols="12" md="4" v-if="index === 0">
            <v-card class="px-3 py-2 default" outlined
              >What are the dates of the opening seasons for the venue?
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <div class="pointer text--secondary" @click="addOpeningSeason">
              <i class="mdi mdi-plus"></i> Add Another Opening Season
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >Click the <strong>Add Another Space</strong> to add more venue spaces
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col cols="12" md="8">
            <h5>Type of events</h5>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col cols="12" md="8">
            <v-item-group multiple v-model="form.event_types">
              <v-container>
                <v-row>
                  <v-col v-for="(eventType, index) in allEventTypes" :key="index" class="p-0 m-0 custom-item-column">
                    <v-item :value="eventType">
                      <v-card
                        :color="isEventTypeSelected(eventType) === true ? 'primary' : 'default'"
                        class="d-flex align-center"
                        dark
                        height="75"
                        width="75"
                        @click="setActive(eventType)"
                        outlined
                      >
                        <v-scroll-y-transition>
                          <div
                            class="text-center flex-grow-1"
                            :class="{ 'black--text': isEventTypeSelected(eventType) === false }"
                          >
                            <v-icon
                              :color="isEventTypeSelected(eventType) === false ? 'primary' : ''"
                              :class="eventType.icon"
                            ></v-icon>
                            <div>
                              <small>{{ eventType.name }}</small>
                            </div>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined>What kind of events are available for the venue?</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Add event name"
              v-model="newEventType"
              append-icon="mdi-plus"
              :rules="[getRules.event]"
              @keydown.enter.prevent="addEventType"
              @click:append="addEventType"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="px-3 py-2 default" outlined
              >Add new event type and click <strong>+</strong> icon button
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "OpeningHours",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    selectedEvents: [],
    currentDate: new Date().toISOString().substr(0, 10),
    dateMenu: false,
    furnitureItems: [
      "Carpets",
      "Meeting Rooms",
      "Laptops",
      "Chair & Table",
      "Exhibitions",
      "Main Gates",
      "Fire Alarms",
      "Emergency Exists"
    ],
    humanResources: ["Waiters", "Show Organizers", "Host", "Cheerleaders", "Security", "Bouncers"],
    eventTypes: [
      {
        name: "Meeting",
        icon: "mdi mdi-presentation",
        selected: false
      },
      {
        name: "Conference",
        icon: "mdi mdi-table-furniture",
        selected: false
      },
      {
        name: "Incentive",
        icon: "mdi mdi-currency-usd-circle",
        selected: false
      },
      {
        name: "Convention",
        icon: "mdi mdi-theater",
        selected: false
      },
      {
        name: "Exhibition",
        icon: "mdi mdi-theater",
        selected: false
      },
      {
        name: "Company Trip",
        icon: "mdi mdi-ticket",
        selected: false
      },
      {
        name: "Wedding",
        icon: "mdi mdi-party-popper",
        selected: false
      },
      {
        name: "Dance",
        icon: "mdi mdi-account-music",
        selected: false
      },
      {
        name: "Screening",
        icon: "mdi mdi-monitor-dashboard",
        selected: false
      },
      {
        name: "Shooting",
        icon: "mdi mdi-camera",
        selected: false
      },
      {
        name: "Show",
        icon: "mdi mdi-theater",
        selected: false
      },
      {
        name: "Business",
        icon: "mdi mdi-office-building-marker",
        selected: false
      },
      {
        name: "Roadshow",
        icon: "mdi mdi-party-popper",
        selected: false
      },
      {
        name: "Product Launch",
        icon: "mdi mdi-rocket-launch",
        selected: false
      },
      {
        name: "Workshop",
        icon: "mdi mdi-shopping",
        selected: false
      },
      {
        name: "Dinner",
        icon: "mdi mdi-room-service",
        selected: false
      },
      {
        name: "Gala",
        icon: "mdi mdi-movie-filter",
        selected: false
      },
      {
        name: "Hybrid",
        icon: "mdi mdi-vector-combine",
        selected: false
      },
      {
        name: "Other",
        icon: "mdi mdi-asterisk",
        selected: false
      }
    ],
    newEventType: "",
    newFurniture: "",
    newCateringType: "",
    newHumanResource: ""
  }),
  computed: {
    ...mapGetters(["getRules", "getVendor", "getIsVendorUpdate"]),
    timings() {
      let times = [];
      for (let hours = 0; hours <= 23; hours++) {
        for (let minutes = 0; minutes <= 60; minutes += 15) {
          let h = hours;
          let m = minutes;
          if (hours === 0) {
            h = "00";
          } else if (hours < 10) {
            h = "0" + hours;
          }
          if (minutes === 0) {
            m = "00";
          } else if (minutes < 10) {
            m = "0" + minutes;
          }
          if (m !== 60) {
            times.push(h + ":" + m);
          }
        }
      }
      return times;
    },
    allEventTypes() {
      this.form.event_types.forEach(event => {
        if (event.name.replace(/ /g, "") !== "") {
          this.eventTypes.push({
            name: event.name,
            icon: event.icon,
            selected: true
          });
        }
      });
      return _.uniqBy(this.eventTypes, "name");
    }
  },
  methods: {
    moment,
    setActive(eventType) {
      if (this.form.event_types && this.form.event_types.length > 0) {
        if (this.form.event_types.some(event => event.name === eventType.name)) {
          this.form.event_types = this.form.event_types.filter(item => item.name !== eventType.name);
        } else {
          let events = [];
          this.form.event_types.forEach(e => {
            events.push(e);
          });
          this.form.event_types = [];
          events.push(eventType);
          events.forEach(ev => {
            if (ev.name.replace(/ /g, "") !== "") {
              this.form.event_types.push({
                name: ev.name,
                icon: ev.icon
              });
            }
          });
        }
      } else {
        this.form.event_types = [];
        this.form.event_types.push(eventType);
      }
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    isEventTypeSelected(eventType) {
      let isSelected = false;
      if (this.form.event_types) {
        this.form.event_types.forEach(event_type => {
          if (event_type.name === eventType.name) {
            isSelected = true;
          }
        });
      }
      return isSelected;
    },
    continue() {
      this.$emit("locationNextStep", this.index);
    },
    addFurniture() {
      this.furnitureItems.push(this.newFurniture);
      this.newFurniture = "";
    },
    addHumanResource() {
      this.humanResources.push(this.newHumanResource);
      this.newHumanResource = "";
    },
    addEventType() {
      if (this.$refs.openingHoursForm.validate()) {
        if (this.newEventType.replace(/ /g, "") !== "") {
          this.eventTypes.push({
            name: this.newEventType,
            icon: "mdi mdi-auto-fix",
            selected: true,
            removable: true
          });
          this.newEventType = "";
        }
      }
    },
    addOpeningSeason() {
      this.form.seasonal_timings.push({
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
        start_date_menu: false,
        end_date_menu: false,
        removable: true
      });
    },
    removeOpeningSeason(removeOpeningSeasonIndex) {
      let openingSeasons = this.form.seasonal_timings;
      this.form.seasonal_timings = _.filter(openingSeasons, (openingSeason, index) => {
        if (removeOpeningSeasonIndex !== index) {
          return openingSeason;
        }
      });
    }
  },
  watch: {
    selectedEvents: function(selectedIndexes) {
      _.forEach(this.eventTypes, (eventType, index) => {
        let isExists = false;
        _.forEach(selectedIndexes, selectedIndex => {
          this.eventTypes[selectedIndex].selected = true;
          if (index === selectedIndex) {
            isExists = true;
          }
        });
        if (isExists === false) {
          this.eventTypes[index].selected = false;
        }
      });
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}

.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}

.equipment-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
</style>
