<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form ref="form">
      <v-container>
        <!--Meeting Spaces (START)-->
        <!-- <v-row class="mt-3 mb-0">
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-card class="px-3 py-2 bg-color-grey" outlined>
              <v-row class="py-0 my-0">
                <v-col cols="12" md="1" class="py-0 my-0">
                  <v-checkbox
                    class="py-0 my-0"
                    v-model="form.vendor_specifications.has_meeting_space"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="11" class="py-0 my-0">
                  <v-row class="py-0 my-0">
                    <v-col cols="12" md="12" class="py-0 my-0">
                      Meeting Spaces
                    </v-col>
                    <v-col
                      v-if="form.vendor_specifications.has_meeting_space"
                      cols="12"
                      md="12"
                      class="py-0 my-0"
                    >
                      <v-text-field
                        label="Number of meeting spaces"
                        :rules="[getRules.nonNegativeQuantity, getRules.number]"
                        min="0"
                        v-model="
                          form.vendor_specifications.total_meeting_spaces
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0">
            <v-card class="px-3 py-2 default" outlined
              >Enter number of meeting spaces in the hotel</v-card
            >
          </v-col>
        </v-row> -->
        <!--Meeting Spaces (END)-->

        <!--Event Spaces (START)-->
        <!--        <v-row class="mt-3 mb-0">
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-card class="px-3 py-2 bg-color-grey" outlined>
              <v-row class="py-0 my-0">
                <v-col cols="12" md="1" class="py-0 my-0">
                  <v-checkbox class="py-0 my-0" v-model="form.vendor_specifications.has_event_space"></v-checkbox>
                </v-col>
                <v-col cols="12" md="11" class="py-0 my-0">

                  <v-row class="py-0 my-0">
                    <v-col cols="12" md="12" class="py-0 my-0">
                      Event Spaces
                    </v-col>
                    <v-col v-if="form.vendor_specifications.has_event_space" cols="12" md="12" class="py-0 my-0">

                      <v-row class="py-0 my-0" v-for="(event_space, eventSpaceIndex) in form.event_spaces" :key="'esi'+eventSpaceIndex">
                        <v-col cols="12" md="6" class="py-0 my-0">
                          <v-select v-model="event_space.type" label="Type of events" :items="['Meeting', 'Conference', 'Incentive', 'Convention', 'Exhibition', 'Company Trip', 'Wedding', 'Party', 'Dance', 'Screening', 'Shooting', 'Show', 'Business', 'Roadshow', 'Product Launch', 'Workshop', 'Dinner', 'Gala', 'Hybrid']"></v-select>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0 my-0">
                          <v-text-field v-model="event_space.total_spaces" label="Number of spaces" min="0" type="number"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row class="py-0 my-0">
                        <v-col cols="12" md="12">
                          <div class="pointer text&#45;&#45;secondary" @click="addEventSpace"><i class="mdi mdi-plus"></i> Add an event space</div>
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" class="py-0 my-0">
          <v-card class="px-3 py-2 default" outlined>Add number of event spaces in the hotel</v-card>
          </v-col>
        </v-row>-->
        <!--Event Spaces (END)-->

        <v-row>
          <v-col cols="12" md="8">
            <spaces :form="form"></spaces>
          </v-col>
          <v-col cols="12" md="4">
            <v-card flat class="pa-3 default"
              >Let us know about the basic information of your venue or
              hotel</v-card
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3"
      >Continue</v-btn
    >
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="
        (getVendor && getVendor.status !== 'active') ||
        getIsVendorUpdate === false
      "
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const Spaces = () =>
  import(/* webpackChunkName: "spaces" */ "@/components/sections/Spaces");
export default {
  name: "MeetingAndEvents",
  props: ["name", "index", "form"],
  components: { Spaces },
  data: () => ({}),
  computed: {
    ...mapGetters([
      "getRules",
      "getAuthErrors",
      "getVendor",
      "getIsVendorUpdate",
    ]),
  },
  methods: {
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("hotelNextStep", this.index);
    },
    addEventSpace() {
      this.form.event_spaces.push({
        type: null,
        total_spaces: null,
        removable: true,
        editable: false,
      });
    },
  },
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}
.feature-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
.custom-editable {
  border-bottom: 1px solid #b3b3b3;
}
.custom-checkbox {
}
</style>
